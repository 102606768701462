import styled from 'styled-components';

export const Balance: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5vmin;
  margin-top: 13vmin;

  .balance {
    font-size: 14vmin;
    font-weight: 600;
    line-height: 14vmin;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #EAEBF5;

    &-text {
      font-size: 4.8vmin;
      font-weight: 400;
      line-height: 4.8vmin;
      text-align: center;
      color: #43434A;
      margin-top: 4vmin;
    }
  }
`;

export const Energy: any = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .energy {
    font-size: 4.8vmin;
    font-weight: 400;
    line-height: 4.8vmin;
    color: #43434A;
  }
`;
